define('incus-vastaanota/utils/demo', ['exports', 'ember', 'incus-vastaanota/models/invoice', 'incus-vastaanota/config/environment'], function (exports, _ember, _incusVastaanotaModelsInvoice, _incusVastaanotaConfigEnvironment) {
    exports['default'] = {
        check_call: function check_call(method, path, params) {
            if (_incusVastaanotaConfigEnvironment['default'].environment === "demo" && (method === 'PUT' || method === 'DELETE')) {
                return true;
            }
            return false;
        },

        check_env: function check_env() {
            return _incusVastaanotaConfigEnvironment['default'].environment === "demo";
        },

        handle_call: function handle_call(apixclient, method, path, params) {
            var stub_response = '<?xml version="1.0" encoding="UTF-8" standalone="yes"?><Response><Status>OK</Status><StatusCode>2G00</StatusCode><Content><Group></Group></Content></Response>';
            var data = stub_response;

            data = _ember['default'].$.parseXML(data);
            data = apixclient._transformToObject(data);

            return new Promise(function (resolve) {
                resolve(data);
            });
        },

        get_dl_link: function get_dl_link(apixclient, uniqueCompanyId, transferId, transferKey, uniqueMessageID, role, type, extension) {
            return null;
        }

    };
});