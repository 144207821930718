define('incus-vastaanota/pods/terms/route', ['exports', 'ember', 'incus-vastaanota/config/environment'], function (exports, _ember, _incusVastaanotaConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({

    apixclient: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    countryselector: _ember['default'].inject.service(),

    model: function model(params) {
      var countryselector = this.get("countryselector");
      var model = _ember['default'].Object.extend({
        countryselector: countryselector
      }).create();
      return model;
    }
  });
});