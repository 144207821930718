define('incus-vastaanota/pods/registration/receipt/route', ['exports', 'ember', 'incus-vastaanota/config/environment'], function (exports, _ember, _incusVastaanotaConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({

    i18n: _ember['default'].inject.service(),
    countryselector: _ember['default'].inject.service(),

    model: function model(param) {
      var businessStr = this.get("countryselector.url") === "fakturamappen" ? "Apix Messaging Ab" : "Apix Messaging Oy";
      var businessId = this.get("countryselector.url") === "fakturamappen" ? "556964-9287" : "2332748-7";
      var formData = window.registrationFormData;
      var termsUrl = _incusVastaanotaConfigEnvironment['default'].clientHostRoot + '/#/terms';

      if (!formData || typeof formData !== 'object') {

        this.get('notify').error(this.get("i18n").t("registration.receipt.dataError").toString(), {
          closeAfter: null
        });

        this.transitionTo('registration.info');
      }
      delete window.registrationFormData;
      //formData.businessId.field = "123123-4444"
      var userBid = formData.businessId.field.replace("-", "");
      var vatIdSE = "SE" + userBid + "01";
      var isLaskumappi = this.get("countryselector.url") === "laskumappi";
      var receiveIsOn = formData.categories.find(function (c) {
        return c.name === "receive";
      }).field;

      var model = _ember['default'].Object.extend({
        receiveIsOn: receiveIsOn,
        isLaskumappi: isLaskumappi,
        businessStr: businessStr,
        businessId: businessId,
        termsUrl: termsUrl,
        userBid: userBid,
        vatId: vatIdSE,
        formData: formData,
        date: moment().format("DD/MM/YYYY")
      }).create();
      return model;
    },
    actions: {
      printReceipt: function printReceipt() {
        window.print();
      },
      done: function done() {
        this.transitionTo("login");
      }
    }

  });
});